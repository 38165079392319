<template>
  <b-container
    v-if="currentTabIndex === 1"
    class="px-1 py-2"
    fluid
  >
    <b-row class="mb-2">

      <b-col
        cols="12"
        md="6"
        order="5"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_search"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_search"
            v-model="tableCallRequest.filter"
            type="text"
            size="sm"
            debounce="1000"
            placeholder="enter search string"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="2"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-select
            id="filter_status"
            v-model="filter.filter_status"
            size="sm"
            :options="options.filter_statuses"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="3"
      >
        <b-form-group
          label="Requested From"
          label-for="filter_requested_from"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_requested_from"
            v-model="filter.date_from"
            size="sm"
            type="date"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="4"
      >
        <b-form-group
          label="Requested To"
          label-for="filter_requested_to"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_requested_to"
            v-model="filter.date_to"
            size="sm"
            type="date"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-table
          ref="table"
          small
          hover
          responsive
          show-empty
          class="mt-2"
          :stacked="isMobile()"
          :per-page="tableCallRequest.perPage"
          :current-page="tableCallRequest.currentPage"
          :items="tableCallRequestProvider"
          :fields="tableCallRequest.fields"
          :sort-by.sync="tableCallRequest.sortBy"
          :sort-desc.sync="tableCallRequest.sortDesc"
          :sort-direction="tableCallRequest.sortDirection"
          :filter="tableCallRequest.filter"
          :filter-included-fields="tableCallRequest.filterOn"
          :busy="tableCallRequest.busy"
        >
          <template #cell(index)="data">
            {{ tableCallRequest.currentPage * tableCallRequest.perPage - tableCallRequest.perPage + (data.index + 1) }}
          </template>

          <template #cell()="data">
            <div class="text-nowrap">
              {{ data.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
              <b-button
                size="sm"
                @click="onShowDetailForm(row.item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableCallRequest.perPage"
            :options="tableCallRequest.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableCallRequest.currentPage"
          :total-rows="tableCallRequest.totalRows"
          :per-page="tableCallRequest.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-view-detail"
      title="View Details"
      size="lg"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.call_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.call_request?.customer?.first_name} ${selected.call_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.call_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.call_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.call_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                ref="customerLogTable"
                small
                hover
                responsive
                show-empty
                class="mt-2"
                :stacked="isMobile()"
                :per-page="tableCSLogSettings.perPage"
                :current-page="tableCSLogSettings.currentPage"
                :items="tableCommunicationLogProvider"
                :fields="tableCSLogSettings.fields"
                :sort-by.sync="tableCSLogSettings.sortBy"
                :sort-desc.sync="tableCSLogSettings.sortDesc"
                :sort-direction="tableCSLogSettings.sortDirection"
                :filter="tableCSLogSettings.filter"
                :filter-included-fields="tableCSLogSettings.filterOn"
                :busy="tableCSLogSettings.busy"
              >

                <template #cell(index)="data">
                  {{ tableCSLogSettings.currentPage * tableCSLogSettings.perPage - tableCSLogSettings.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      @click="onViewCommunicationLog(row.item)"
                    >
                      View
                    </b-button>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCSLogSettings.perPage"
                  :options="tableCSLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCSLogSettings.currentPage"
                :total-rows="tableCSLogSettings.totalRows"
                :per-page="tableCSLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log"
      title="Communication Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="moda-cancel-request"
      title="Cancel Request"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="cancelForm.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserReportService, SharedLogService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'UserCallRequestHistories',

  middleware: ['auth', 'user'],

  mixins: [formatter, misc],

  props: {
    currentTabIndex: {
      type: Number,
      default: () => 1
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        filter_status: 'All'
      },
      selected: {
        call_request: null,
        log: null
      },
      options: {
        filter_statuses: [
          { text: 'All', value: 'All' },
          { text: 'Closed', value: 'Closed' },
          { text: 'Cancelled', value: 'Cancelled' }
        ]
      },
      cancelForm: {
        id: 0,
        remarks: ''
      },
      tableCallRequest: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 13, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'status', class: 'text-center', sortable: true },
          { mobile: 3, key: 'customer.first_name', label: 'First Name' },
          { mobile: 4, key: 'customer.last_name', label: 'Last Name' },
          { mobile: 5, key: 'customer.mobile_number', label: 'Mobile Number' },
          { mobile: 6, key: 'call_attempt', label: 'Unsuccessful Call Attempt', class: 'text-center' },
          { mobile: 7, key: 'last_call' },
          { mobile: 8, key: 'last_status' },
          { mobile: 9, key: 'last_agent' },
          { mobile: 10, key: 'closed_at', label: 'Closed at' },
          { mobile: 11, key: 'cancelled_at', label: 'Cancelled at' },
          { mobile: 12, key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      tableCSLogSettings: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 8, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'agent_name', label: 'Agent' },
          { mobile: 3, key: 'channel_name', label: 'Channel', class: 'text-center' },
          { mobile: 4, key: 'call_status', class: 'text-center' },
          { mobile: 5, key: 'unsuccessful_reason_name', label: 'Unsuccessful Reason' },
          { mobile: 6, key: 'disposition_type_name', label: 'Disposition Type' },
          { mobile: 7, key: 'disposition_name', label: 'Disposition' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableCallRequestProvider (ctx) {
      this.tableCallRequest.busy = true
      return await UserReportService.getCallRequestHistories(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_from: this.filter.date_from,
          filter_to: this.filter.date_to,
          filter_status: this.filter.filter_status
        })
      ).then(({ data }) => {
        this.tableCallRequest.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableCallRequest.busy = false
      })
    },

    async tableCommunicationLogProvider (ctx) {
      this.tableCSLogSettings.busy = true
      return await SharedLogService.getCustomerLogs(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          customer_id: this.selected.call_request.customer?.id,
          request_id: this.selected.call_request.id
        })
      ).then(({ data }) => {
        this.tableCSLogSettings.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableCSLogSettings.busy = false
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want cancel request?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserReportService.put(this.cancelForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('moda-cancel-request')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onShowDetailForm (current) {
      this.selected.call_request = { ...current }
      this.$bvModal.show('modal-view-detail')
    },

    onViewCommunicationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log')
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
